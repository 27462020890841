<template>
  <div>
    <v-menu
      v-model="showMenu"
      :close-on-click="oneMonth || competencia.length == 2"
      :close-on-content-click="false"
      transition="scroll-y-transition"
      offset-y
      right
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          dense
          readonly
          hide-details
          class="monthly-filter-field"
          placeholder="nenhuma"
          prepend-icon="mdi-calendar"
          :prefix="oneMonth ? 'Competência' : 'Período:'"
          :value="textFieldValue"
          :clearable="clearable"
          @click:clear="competencia = null"
          @click:prepend.stop="on.click"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="competencia"
        :range="!oneMonth"
        no-title
        type="month"
        @click:month="showMenu = oneMonth ? false : competencia.length != 2;"
        @change="changeHandler"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import getValidDatesTuple from '@/helpers/getValidDatesTuple.js';

export default {
  name: 'monthly-filter',
  computed: {
    ...mapGetters(['intervaloCompetencia']),
    textFieldValue: function () {
      return this.oneMonth ? this.$options.filters.toFullMonth(this.competencia) : this.$options.filters.toMonthPeriod(this.competencia)
    },
  },
  created: function () {
    this.$emit('change', this.oneMonth ? this.intervaloCompetencia[0] : this.intervaloCompetencia);
  },
  data: function () {
    return {
      competencia: this.oneMonth ? this.$store.getters.intervaloCompetencia[0] : this.$store.getters.intervaloCompetencia,
      showMenu: false,
    }
  },
  methods: {
    ...mapMutations(['setIntervaloCompetencia']),
    changeHandler: function (value) {      
      if (!value) {
        return;
      }

      const nextInterval = getValidDatesTuple(value);
      const [oldIni, oldFim] = this.intervaloCompetencia;
      const [newIni, newFim] = nextInterval;

      if (nextInterval.length > 1 && ( oldIni != newIni || oldFim != newFim )) {
        this.setIntervaloCompetencia(nextInterval);
        this.$emit('change', this.oneMonth ? value : nextInterval);
      }
    },
  },
  model: {
    prop: 'interval',
    event: 'change'
  },
  props: {
    interval: {
      type: [Array, String],
      default: () => []
    },
    oneMonth: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style lang="scss" scoped>
.monthly-filter-field::v-deep {
  margin-top: 0;
   
  .v-input__control > .v-input__slot > .v-text-field__slot > input[readonly] {
    color: unset !important;
  }
}
</style>
